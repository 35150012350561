<template>
    <div>
        <div
            :class="`cloudinary-wrapper ${wrapperClasses} ${classes} ${imageAlign}`"
            :style="css"
        >
            <client-only>
                <div
                    :class="`cld-image ${innerClasses} ${classes} ${imageAlign}`"
                >
                    <!-- <nuxt-img
                        :src="globalSrc.src"
                        :alt="
                            alt && alt[$i18n.locale]
                                ? alt[$i18n.locale]
                                : 'c3controls image'
                        "
                        :width="width"
                    /> -->
                    <picture>
                        <source
                            :srcset="
                                $vuetify.breakpoint.xs
                                    ? globalSrc.srcsetMobile
                                    : globalSrc.srcset
                            "
                            type="image/webp"
                        />
                        <img
                            :src="globalSrc.src"
                            :alt="
                                alt && alt[$i18n.locale]
                                    ? alt[$i18n.locale]
                                    : 'c3controls image'
                            "
                            :width="width"
                            :height="height"
                            loading="lazy"
                        />
                    </picture>
                </div>
            </client-only>
        </div>
        <div
            v-if="caption && caption[$i18n.locale]"
            class="img-caption--wrapper"
        >
            <div class="img-caption--text">{{ caption[$i18n.locale] }}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EmbedSanityImage',
    props: {
        imageId: {
            type: String,
            default: '',
        },
        selectedImage: {
            type: Object,
            default: null,
        },
        originalFilename: {
            type: String,
            default: '',
        },
        alt: {
            type: Object,
            default() {
                return { en: 'c3controls', es: 'c3controls' }
            },
        },
        borderRadius: {
            type: Boolean,
            default: true,
        },
        width: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '',
        },
        classes: {
            type: String,
            default: '',
        },
        wrapperClasses: {
            type: String,
            default: '',
        },
        innerClasses: {
            type: String,
            default: '',
        },
        imageAlign: {
            type: String,
            default: 'center',
        },
        fit: {
            type: String,
            default: 'fill',
        },
        mobileWidth: {
            type: String,
            default: '100',
        },
        tabletWidth: {
            type: String,
            default: '100',
        },
        desktopWidth: {
            type: String,
            default: '100',
        },
        caption: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            imageAsset: null,
        }
    },
    computed: {
        globalSrc() {
            return {
                src: `${this.calcImgURL(1)}`,
                srcset: `${this.calcImgURL(1)},${this.calcImgURL(
                    0.4
                )} 0.4x, ${this.calcImgURL(0.5)} 0.5x, ${this.calcImgURL(
                    1.5
                )} 1.5x, ${this.calcImgURL(2)} 2x, ${this.calcImgURL(
                    2.5
                )} 2.5x, ${this.calcImgURL(3)} 3x`,
                srcsetMobile: `${this.calcImgURL(1)} 0.3x, ${this.calcImgURL(
                    1
                )} 0.4x, ${this.calcImgURL(1)} 0.5x,`,
            }
        },
        css() {
            if (process.client && this.context !== 'specific') {
                const bp = this.$vuetify.breakpoint
                if (bp.smAndDown) {
                    return {
                        maxWidth: `${this.mobileWidth}%`,
                        // float: 'none',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                    }
                } else if (bp.mdAndDown) {
                    return { maxWidth: `${this.tabletWidth}%` }
                } else if (bp.lgAndUp) {
                    return { maxWidth: `${this.desktopWidth}%` }
                }
            }
            return {}
        },
    },

    methods: {
        calcImgURL(dpr) {
            const width = this.height
                ? ''
                : this.width
                ? `w=${Math.floor(parseFloat(this.width) * dpr)}`
                : ''
            const height = this.height
                ? `h=${Math.floor(parseFloat(this.height) * dpr)}`
                : ''
            const fit = `&fit=${this.fit}`
            const ogFileName =
                this.selectedImage?.asset?.originalFilename ||
                this.originalFilename ||
                'c3controls-icon.jpg'

            return this.$img(
                `${process.env.ASSET_HOSTNAME}${
                    ogFileName || 'c3controls-icon.jpg'
                }?${height}${width}${fit}`,
                { format: 'webp' }
            )
            // return `${process.env.ASSET_HOSTNAME}${ogFileName}?${height}${width}${fit}`
        },
    },
}
</script>
<style scoped>
.footer-icons {
    margin: 0;
}
.custom-footer-class {
    margin-top: 10px !important;
}
</style>
